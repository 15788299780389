import get from 'lodash/get';
import isNil from 'lodash/isNil';

import WidgetWrapper from '../../wrapper/WidgetWrapper';
import dom from '../../wrapper/DomWrapper';
import CookieModal from './CookieModal';
import CookieAnalyticsManager from './CookieAnalyticsManager';
import CookieModalLink from './CookieModalLink';
import CookieNotice from './CookieNotice';
import { getUserLocation } from './utils';

import {
  COOKIE_MODAL,
  COOKIE_NOTICE_TOOLBAR,
  COOKIE_MODAL_HREF,
} from './constants';

class CookieBannerManager extends WidgetWrapper {
  init = () => {
    this.elCookieBanner = dom.getElement(this.selector);

    if (!this.elCookieBanner) return;

    const elCookieModal = dom.getElement(COOKIE_MODAL);

    if (!elCookieModal) return;

    this.cookieAnalyticsManager = new CookieAnalyticsManager();
    this.cookieModal = new CookieModal(COOKIE_MODAL, this);
    this.cookieModal.init();

    this.cookieNotice = new CookieNotice(COOKIE_NOTICE_TOOLBAR, this);
    this.cookieNotice.init();

    this.cookieModalLink = new CookieModalLink(COOKIE_MODAL_HREF, this);
    this.cookieModalLink.init();

    this.handleGeoipData();
  };

  handleGeoipData = () => {
    getUserLocation()
      .then(
        (data) => {
          const geoLocation = get(data, ['country_code'], null);
          this.cookieAnalyticsManager.updateGeoLocation(geoLocation);
        },
      )
      .finally(() => this.handleCookieBanner());
  }

  handleCookieBanner = () => {
    this.cookieAnalyticsManager.updateCountry();
    const isCookieAlreadyExists = this.cookieAnalyticsManager.checkIsAlreadyHandled();

    if (isCookieAlreadyExists) return;

    const isEuro = this.cookieAnalyticsManager.checkIsEurope();
    const isBannerEnabled = this.cookieNotice.checkIsEnabled();

    if (!isBannerEnabled && !isEuro) return;

    this.cookieNotice.show();
  }

  showModal = (event) => {
    event.preventDefault();
    this.cookieModal.show();
  }

  getAnalyticsInitial = () => this.cookieAnalyticsManager.getInitialValue()

  accept = (value) => {
    const valueToSave = isNil(value) ? this.getAnalyticsInitial() : value;
    this.cookieAnalyticsManager.saveSettingsToCookies(valueToSave);
    this.cookieNotice.hide();
  }
}

export default CookieBannerManager;
