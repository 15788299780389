import dom from '../../../../wrapper/DomWrapper';
import getStateValue from '../../../../helpers/getStateValue';
import loadImage from '../../../../helpers/loadImage';
import genAbsoluteUrl from './genAbsoluteUrl';
import browser from '../../../../helpers/browser';
import lazyLoad from '../../../LazyLoad';

const error = '<div class="picture-wrap_unavailable" />';

const addGridItemImages = (element, { previewImageUrl }) => {
  const gridThumb = dom.getElement('[data-grid-image]', element);

  if (!gridThumb) return;

  const { gridImage } = gridThumb.dataset;
  const fileStorage = getStateValue('apiUrls.fileStorageService');

  if (!fileStorage) return;

  const src = previewImageUrl && genAbsoluteUrl(fileStorage, previewImageUrl);

  if (gridImage === 'background') {
    if (!src) dom.addClass(gridThumb, 'blog-item__card-empty');
    else {
      loadImage(src)
        .then(() => {
          gridThumb.style['background-image'] = `url("${src}")`;
        })
        .catch(() => {
          dom.addHtml(dom.getElement('.blog-item__overlay', gridThumb), error);
        });
    }
  }

  if (gridImage === 'image') {
    const imageContainer = dom.getElement('.blog-item__thumbnails-img', gridThumb);
    const img = dom.createElement('img');
    dom.addClass(img, 'lazy');

    if (browser.isIe()) img.setAttribute('data-object-fit', 'cover');

    imageContainer.appendChild(img);
    img.setAttribute('data-src', src);
    img.onerror = () => dom.addHtml(imageContainer, error);
    setTimeout(() => lazyLoad(), 0);
  }
};

export default addGridItemImages;
