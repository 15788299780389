import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import get from 'lodash/get';

import currenciesConfig from '@sp/fixtures/json/currencies.json';
import {
  DEFAULT_CURRENCY_FORMAT_OPTIONS,
  DEFAULT_CURRENCY_GROUP_LENGTH,
} from '../constants';

export const formatWholePricePart = (
  price,
  currencyGroupSeparator,
  groupMaxLength = DEFAULT_CURRENCY_GROUP_LENGTH,
) => {
  if ((!isNumber(price) && !isString(price)) || !currencyGroupSeparator) return '';

  const priceString = String(price);

  if (!priceString.length) return '';

  if (priceString.length <= groupMaxLength) return priceString;

  let formattedPrice = '';
  let { length } = priceString;

  while (length > 0) {
    const point = length - groupMaxLength;
    const separator = formattedPrice ? currencyGroupSeparator : '';
    const currentGroup = priceString.substring(point >= 0 ? point : 0, length);

    formattedPrice = `${currentGroup}${separator}${priceString}`;
    length -= groupMaxLength;
  }

  return formattedPrice;
};

const formatPrice = (
  price,
  options = DEFAULT_CURRENCY_FORMAT_OPTIONS,
) => {
  const {
    currencyGroupSeparator,
    currencyDecimalSeparator,
    currency,
  } = options;

  const withDecimals = get(currenciesConfig, [currency, 'decimal_digits'], 2) > 0;

  const [wholePart, fractionalPart] = (Number.isFinite(price) ? price.toFixed(2) : price).toString().split('.');
  const formattedWholePrice = formatWholePricePart(wholePart, currencyGroupSeparator);
  const formattedFractionalPrice = withDecimals ? `${currencyDecimalSeparator}${fractionalPart}` : '';

  return `${formattedWholePrice}${formattedFractionalPrice}`;
};

export default formatPrice;
